import React, { useState, useEffect, useMemo } from 'react';
import { AppBar, Box, Button, ClickAwayListener, Container, Drawer, Grow,
         IconButton, Link, MenuItem, MenuList, Paper, Popper, Stack,
         Toolbar, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ArrowDropDown, Menu } from '@mui/icons-material';

import useFetchData from '../Hooks/useFetchData';
import Notifications from '../Components/Notifications';
import FadeInOnScroll from '../Components/FadeInOnScroll';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;

const Navbar = ({ content, logoLight, logoDark }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [navigation, setNavigation] = useState([]);
  const [navMicrosoft, setNavMicrosoft] = useState(null);
  const [openMs, setOpenMs] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const { attributes } = content;

  const fetchParams = useMemo(() => ({ nested: '1', populate: '*' }), []);
  const { loading, data, error } = useFetchData(`menus/${attributes.menuId}`, fetchParams);

  useEffect(() => {
    if (error && !errorMessage) {
      setErrorMessage(error.message);
    } else if (data) {
      setNavigation(data.attributes.items.data);
    }
  }, [error, data, errorMessage]);

  if (loading) return <Notifications loading={loading} />;
  if (errorMessage) return <Notifications error={errorMessage} />;

  const handleClick = (event) => {
    setNavMicrosoft(event.currentTarget);
    setOpenMs(prev => !prev);
  };

  const handleClose = () => setOpenMs(false);

  const handleListKeyDown = (event) => {
    if(event.key === 'Tab') {
      event.preventDefault();
      setOpenMs(false);
    }
  };

  const handleDrawer = () => setOpenDrawer(prev => !prev);

  const navBarStyle = {
    backgroundColor: attributes.barBgColor || 'transparent',
    boxShadow: attributes.barShadow || 'none',
    margin: attributes.barMargin || 0,
    padding: attributes.barPadding || 0,
    border: attributes.barBorder || 0,
    borderRadius: attributes.barBorderRadius || 0,
    top: attributes.posTop || 'auto',
    bottom: attributes.posBottom || 'auto',
    left: attributes.posLeft || 'auto',
    right: attributes.posRight || 'auto',
  };

  const navGridStyle = {
    backgroundColor: attributes.gridBgColor || 'transparent',
    margin: attributes.gridMargin || 0,
    padding: attributes.gridPadding || 0,
    border: attributes.gridBorder || 0,
    borderRadius: attributes.gridBorderRadius || 0,
    display: attributes.gridDisplay || 'flex',
    alignItems: attributes.gridAlignItems || 'center',
  };

  const navMobileStyle = {
    backgroundColor: attributes.mobileLogoBg || 'transparent',
  }

  const buttonSx = {
    backgroundColor: attributes.buttonBgColor,
    color: `${attributes.buttonFontColor} !important`,
    border: attributes.buttonBorder,
    width: attributes.buttonWidth || 'auto',
    padding: attributes.buttonPadding,
    margin: attributes.buttonMargin,
    borderRadius: attributes.buttonRadius,
    textTransform: attributes.textTransform || 'none',
    justifyContent: attributes.buttonJustifyContent || 'none',
    '&:hover': {
      backgroundColor: attributes.buttonBgColorHover || 'transparent',
      color: `${attributes.buttonFontColorHover} !important`,
      border: attributes.buttonBorderHover,
    }
  };

  const popperSx = {
    backgroundColor: attributes.popperBgColor,
    color: `${attributes.popperFontColor}`,
    padding: attributes.popperPadding,
    borderRadius: attributes.popperRadius,
    justifyContent: attributes.popperJustify,
    textTransform: attributes.textTransform || 'none',
    '&:hover': {
      backgroundColor: attributes.popperBgColorHover,
      color: `${attributes.popperFontColorHover}`,
    }
  };

  const navLogo = (
    <Link href="/" className="logo-link">
      <img src={`${BASE_API_URL}${logoDark?.url || ''}`} alt={logoDark?.alternativeText} className="logo-img" style={{maxWidth: '30vw', width: '100%'}} />
    </Link>
  );

  const navBar = (
    <Stack direction={attributes.navDirection} spacing={attributes.navSpacing} justifyContent={attributes.navJustifyContent} className="navbar-stack">
      {navigation.map((navItem, index) => (
        <NavItem
          key={index}
          navItem={navItem}
          buttonVariant={attributes.buttonVariant || ''}
          buttonSize={attributes.buttonSize || ''}
          disabled={attributes.disabled || false}
          buttonElevation={attributes.buttonElevation || false}
          disableFocusRipple={attributes.disableFocusRipple || false}
          disableRipple={attributes.disableRipple || false}
          fullWidth={attributes.fullWidth || false}
          sx={buttonSx}
          popperSx={popperSx}
          handleClick={navItem.attributes.children.data.length > 0 ? handleClick : null}
          openMs={openMs}
          anchorEl={navMicrosoft}
          handleClose={handleClose}
          handleListKeyDown={handleListKeyDown}
        />
      ))}
    </Stack>
  );

  return (
    <>
      {attributes.navType === 'normal' && (
        <NormalNavBar position={attributes.navPosition} className={`NavBar ${attributes.className}`} logo={navLogo} navbar={navBar} navBarStyle={navBarStyle} navGridStyle={navGridStyle} />
      )}
      {attributes.navType === 'fade-in' && attributes.fadeInOnScroll && (
        <FadeInNavBar
          fadeInThreshold={attributes.fadeInThreshold}
          fadeInDirection={attributes.fadeInDirection}
          position={attributes.navPosition}
          className={`NavFloat ${attributes.className}`}
          logo={navLogo}
          navbar={navBar}
          navBarStyle={navBarStyle}
          navGridStyle={navGridStyle}
        />
      )}
      {attributes.navType === 'mobile' && (
        <MobileNavBar
          position={attributes.navPosition}
          className={attributes.className}
          handleDrawer={handleDrawer}
          openDrawer={openDrawer}
          logo={navLogo}
          navbar={navBar}
          navBarStyle={navBarStyle}
          navGridStyle={navGridStyle}
          navMobileStyle={navMobileStyle}
        />
      )}
    </>
  );
}

const NavItem = ({ navItem, buttonVariant, buttonSize, color, disabled, buttonElevation, disableFocusRipple, disableRipple, fullWidth, sx, popperSx, handleClick, openMs, anchorEl, handleClose, handleListKeyDown }) => {
  console.log(buttonVariant);
  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={navItem.attributes.children.data.length > 0 ? <ArrowDropDown /> : null}
        variant={buttonVariant}
        size={buttonSize}
        className={navItem.attributes.className}
        href={navItem.attributes.url}
        sx={sx}
        disabled={disabled}
        disableElevation={buttonElevation}
        disableFocusRipple={disableFocusRipple}
        disableRipple={disableRipple}
        fullWidth={fullWidth}
      >
        {navItem.attributes.title}
      </Button>
      {navItem.attributes.children.data.length > 0 && (
        <NavDropdown
          fullWidth={true}
          open={openMs}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleListKeyDown={handleListKeyDown}
          childrenData={navItem.attributes.children.data}
          popperSx={popperSx}
        />
      )}
    </>
  );
};

const NavDropdown = ({ open, anchorEl, handleClose, handleListKeyDown, childrenData, popperSx }) => {
  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition disablePortal className="Menu">
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown} id="composition-menu" className="dropdown">
                {childrenData.map((subItem, index) => (
                  <MenuItem key={index} onClick={handleClose}>
                    <Link
                      href={subItem.attributes.url}
                      className={subItem.attributes.className}
                      sx={popperSx}
                      variant="navigation"
                    >{subItem.attributes.title}</Link>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const NormalNavBar = ({ position, className, logo, navbar, navBarStyle, navGridStyle }) => (
  <AppBar position={position} className={className} style={navBarStyle}>
    <Container className="desktop" maxWidth={false}>
      <Grid container style={navGridStyle}>
        <Grid xs={4}>{logo}</Grid>
        <Grid xs={8} justifyContent="flex-end">{navbar}</Grid>
      </Grid>
    </Container>
  </AppBar>
);

const FadeInNavBar = ({ fadeInThreshold, fadeInDirection, position, className, logo, navbar, navBarStyle, navGridStyle }) => (
  <FadeInOnScroll threshold={fadeInThreshold} direction={fadeInDirection}>
    <AppBar position={position} className={className} style={navBarStyle}>
      <Container className="desktop" maxWidth={false}>
        <Grid container style={navGridStyle}>
          <Grid xs={4}>{logo}</Grid>
          <Grid xs={8} justifyContent="flex-end">{navbar}</Grid>
        </Grid>
      </Container>
    </AppBar>
  </FadeInOnScroll>
);

const MobileNavBar = ({ position, className, handleDrawer, openDrawer, logo, navbar, navBarStyle, navMobileStyle }) => (
  <React.Fragment>
    <Box className="NavBox" sx={{ flexGrow: 1 }}>
      <AppBar position={position} className={className} style={navBarStyle}>
        <Toolbar className="mobile">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            disableRipple
            onClick={handleDrawer}
          >
            <Menu />
            <Typography variant="h4" component="div" sx={{ flexGrow: 1, ml: 1 }}>Menu</Typography>
          </IconButton>
          <Drawer open={openDrawer} onClose={handleDrawer} anchor="top" className="drawer">
            {navbar}
          </Drawer>
        </Toolbar>
      </AppBar>
    </Box>
    <Box className="MobileLogo" sx={{ flexGrow: 1 }} style={navMobileStyle}>
      {logo}
    </Box>
  </React.Fragment>
);

export default Navbar;