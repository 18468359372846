import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import MUI from './Mui';

const MUITitle = ({ content }) => {
  return(
    <Grid
      container
      className={`Title ${content.className}`}
      spacing={content.spacing || 0}
      direction={content.direction || 'row'}
      justifyContent={content.justifyContent || 'flex-start'}
      alignItems={content.alignItems || 'center'}
      sx={{
        margin: content.margin || 0,
        padding: content.padding || 0,
      }}
    >
      {(content.iconBefore || content.imageBefore) && (
        <Grid>
          {content.iconBefore && (
            <MUI
              type={'mui.svg-icon'}
              content={content.iconBefore}
            />
          )}
          {content.imageBefore && (
            <MUI
              type={'mui.media'}
              content={content.imageBefore}
            />
          )}
        </Grid>
      )}
      
      <Grid>
        {content.title && (
          <MUI
            type={'mui.typography'}
            content={content.title}
          />
        )}
        {content.subtitle && (
          <MUI
            type={'mui.typography'}
            content={content.subtitle}
          />
        )}
      </Grid>

      {(content.iconAfter || content.imageAfter) && (
        <Grid>
          {content.iconAfter && (
            <MUI
              type={'mui.svg-icon'}
              content={content.iconAfter}
            />
          )}
          {content.imageAfter && (
            <MUI
              type={'mui.media'}
              content={content.imageAfter}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default MUITitle;