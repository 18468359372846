import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Notifications from './Notifications';

const SEO = ({ seo }) => {
  const [errorMessage] = useState(null);

  if (errorMessage) return <Notifications error={errorMessage} />

  return(
    <Helmet>
      <title>{seo?.metaTitle}</title>
      <meta name="description" content={seo?.metaDescription} />
      <link rel="canonical" href={seo?.cononicalURL} />
      <meta name="viewport" content={seo?.metaViewport} />
      <meta name="robots" content={seo?.metaRobots} />

      <meta name="og:image" content={seo?.metaImage.data?.attributes.url || ''} />
      <meta property="og:image" content={seo?.metaImage.data?.attributes.url || ''} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo?.cononicalURL} />
      <meta property="og:title" content={seo?.metaTitle} />
      <meta property="og:description" content={seo?.metaDescription} />

      <link rel="apple-touch-icon" sizes="180x180" href={seo?.metaAppleTouchIcon180?.data?.attributes.url || ''} />
      <link rel="icon" type="image/png" sizes="32x32" href={seo?.metaIcon32?.data?.attributes.url || ''} />
      <link rel="icon" type="image/png" sizes="16x16" href={seo?.metaIcon16data?.attributes.url || ''} />
      <link rel="manifest" href={seo?.metaManifest?.data?.attributes.url || ''} />
      <link rel="mask-icon" href={seo?.metaMaskIcon?.data?.attributes.url || ''} color={seo?.metaMaskIconColor} />
      <meta name="msapplication-TileColor" content={seo?.metaMsTileColor} />
      <meta name="theme-color" content={seo?.metaThemeColor} />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/js/all.min.js" crossorigin="anonymous"></script>

      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    </Helmet>
  );
}

export default SEO;